import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getProductToken } from 'emergency_data/src/utils/getProductToken';
import {
  CHATBOT_RESPONSES,
  FEEDBACKFORM,
  LIVESTREAMS,
  SUGGESTED_SCRIPT_RESPONSES,
} from '@rsos/constants/alerts/adrCategories';
import { getAlertAdr } from '@rsos/reduxDataStore/src/features/alerts/alertsAPI';
import { PRODUCT_TOKEN } from '@rsos/sinatra/src/actions/types';
import {
  filterDataByKeys,
  parseDataWithDisplaySchema,
  filterDataByCategories,
  ALERTDATAEVENT,
} from './utils';

const RADE_WITH_META = true;

const nonDisplaySchemaKeys = [
  CHATBOT_RESPONSES,
  FEEDBACKFORM,
  LIVESTREAMS,
  SUGGESTED_SCRIPT_RESPONSES,
];

/**
 * Temp solution to fetch first set of alert ADR becasuse there was
 * spike/latency in Hermes/Rockport logs. ADR Continous updates will still come
 * thru websocket connection. This function is intended for CSP/RSP only. B2B-1686 for reference
 * @param {String} alertID - alert ID
 */
export const fetchInitialAlertAdr = createAsyncThunk(
  'adrData/fetchInitialAlertAdr',
  async (alertID, { getState, rejectWithValue }) => {
    let token = getProductToken(getState().sinatra).productToken;

    if (!token) {
      token = localStorage.getItem(PRODUCT_TOKEN);
    }

    try {
      const response = await getAlertAdr(alertID, token, RADE_WITH_META);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/**
 * Fetch alert ADR for iCSP. This uses the auth token as opposed to the product
 * token
 * @param {String} alertID - alert ID
 */

export const fetchAlertAdrICSP = createAsyncThunk(
  'alerts/fetchAlertAdrICSP',
  async (alertID, { rejectWithValue }) => {
    const token = localStorage.getItem(PRODUCT_TOKEN);
    try {
      const response = await getAlertAdr(alertID, token, RADE_WITH_META);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState = {
  loading: false,
  errors: {
    loading: null,
  },
  data: {},
  mostRecentAdrId: null,
};

const adrDataSlice = createSlice({
  name: 'adrData',
  initialState,
  reducers: {
    // Realtime adr update. Assume there was already data for this alert.
    addAdrData(state, action) {
      const { payload = {} } = action;
      const { topic = '' } = payload;

      // data.category.org.suffix
      const category = payload.type.split('.')[1];
      const orgName = payload.type.split('.')[2];

      state.mostRecentAdrId = topic;

      // An entry for alerts should already exist from the fetchAdr requests
      if (state.data[topic]) {
        state.data[topic].data = state.data[topic].data.map(d => {
          // Update the data if orgName and category match
          if (d.orgName === orgName && d.category === category) {
            return {
              ...payload,
              category,
              orgName: orgName,
            };
          }
          return d;
        });
      } else {
        state.data[topic] = {
          data: [
            {
              ...payload,
              category,
              orgName: orgName,
            },
          ],
        };
      }

      return state;
    },
    resetMostRecentAdrId(state) {
      state.mostRecentAdrId = null;
      return state;
    },
  },
  extraReducers: builder => {
    // pending - loader is handled in the DataCard component
    // error - currently no error state on the Alert Data card, Backend team is currently working
    // updates and we should revisit here see if it is needed for error
    builder
      .addCase(fetchInitialAlertAdr.fulfilled, (state, action) => {
        const alertID = action.meta.arg;
        const topic = `alert://${alertID}`;
        const filteredAdr = filterDataByKeys(
          action.payload,
          nonDisplaySchemaKeys,
        );
        const parsedData = parseDataWithDisplaySchema(filteredAdr);

        if (!state.data[topic]) {
          return {
            ...state,
            data: {
              ...state.data,
              [topic]: {
                data: parsedData,
                topic: topic,
              },
            },
            mostRecentAdrId: topic,
          };
        }
      })
      .addCase(fetchAlertAdrICSP.fulfilled, (state, action) => {
        const alertID = action.meta.arg;
        const topic = `alert://${alertID}`;
        if (Object.keys(action.payload).length > 0) {
          const filteredAdrByKeys = filterDataByKeys(
            action.payload,
            nonDisplaySchemaKeys,
          );

          const filteredAdrByCategory = filterDataByCategories(
            filteredAdrByKeys,
            [ALERTDATAEVENT],
          );

          const parsedData = parseDataWithDisplaySchema(filteredAdrByCategory);

          if (!state.data[topic]) {
            return {
              ...state,
              data: {
                ...state.data,
                [topic]: {
                  data: parsedData,
                  topic: topic,
                },
              },
              mostRecentAdrId: topic,
            };
          }
        }
      });
  },
});

export const { addAdrData, resetMostRecentAdrId } = adrDataSlice.actions;
export default adrDataSlice.reducer;
